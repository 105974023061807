<template>
  <v-layout>
    <v-flex >
      <v-card>
        <v-card-title primary-title>
          <div class="justificado">
            <span class="subheading">{{ getQuestions[$route.params.num - 1].titulo }}</span>
          </div>
        </v-card-title>
        <v-divider />
        <v-layout align-center justify-center>
          <v-card-actions>
            <v-radio-group data-cy="radio-group" v-model="opcaoSelecionada" :mandatory="false" class="justificado pl-3 pr-3">
              <v-radio
                v-for="option in getQuestions[$route.params.num - 1].opcoes"
                :label="option.titulo"
                :value="option.valor"
                :key="option.valor" />
            </v-radio-group>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Question',
  data: () => {
    return {
    }
  },
  computed: {
    ...mapGetters(['getQuestions', 'getQuestionnaireType']),
    opcaoSelecionada: {
      get: function () {
        return this.getQuestions[this.$route.params.num - 1].opcaoSelecionada
      },
      set: function (newOption) {
        let number = this.getQuestions[this.$route.params.num - 1].numero
        this.updateSelectedOption({ number, newOption })
      }
    }
  },
  methods: {
    ...mapActions(['updateSelectedOption'])
  }
}
</script>
<style>
.justificado {
  text-align: justify;
}
</style>
